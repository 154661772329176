









































import Vue from 'vue';

import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgPlus from '@/common/svgs/custom/dist/common/plus.svg';
import SvgMinus from '@/common/svgs/custom/dist/common/minus.svg';
import SvgMirrorConfig from '@/common/svgs/mirror-config.svg';

export default Vue.extend({
  components: {
    SvgIcon,
  },

  data() {
    return {
      mirrorParameter: null as any|null,
    };
  },

  methods: {
    async zoomIn() {
      const configurator = await this.$sdkConnector.api;
      configurator.zoomIn();
    },
    async zoomOut() {
      const configurator = await this.$sdkConnector.api;
      configurator.zoomOut();
    },
    async mirror() {
      if (this.mirrorParameter) {
        await this.$sdkConnector.setParameter(this.mirrorParameter, this.mirrorParameter.value === '1.00' ? '-1.00' : '1.00');
      }
    },
  },

  computed: {
    icons(): object {
      return {
        SvgPlus,
        SvgMinus,
        SvgMirrorConfig,
      };
    },
    showMirrorButton() {
      return this.$store.state.coreData.groups.some((group: any) => {
        return group.parameters.some((parameter: any) => {
          if (parameter.key === 'flipConfiguration' && parameter.enabled) {
            this.mirrorParameter = parameter;
            return true;
          }
        });
      });
    },
  },
});
