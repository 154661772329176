
















import Vue, {VueConstructor} from 'vue';
import {removeLoadingScreen} from '@/common/utils/dom';
import {isShowSceneAllowed} from '@/common/utils/access-restriction';

export default (Vue as VueConstructor<Vue & {
  $refs: {
    sceneContainer: HTMLElement,
  },
}>).extend({
  mounted() {
    this.initSceneContainer();
  },
  data() {
    return {
      isCorrectDomain: true,
      hasActivePackage: true,
    };
  },

  computed: {
    isSceneActive(): boolean {
      return this.isCorrectDomain && this.hasActivePackage;
    },
    errorMsg(): string {
      if (this.isSceneActive) {
        return '';
      }
      if (!this.isCorrectDomain) {
        return (this as any).t.t('wrong-domain');
      }
      return (this as any).t.t('no-active-package');
    },
  },

  methods: {
    // Although _checkAccessRights returns a promise
    // you chould not await it because we want to check
    // access rights in background and parallel, for more
    // details see: https://gitlab.com/roomle/web/roomle-ui/-/merge_requests/358#note_500157532
    async _checkAccessRights(): Promise<void> {
      const initData = this.$store.state.uiState.initData;
      const {isCorrectDomain, hasActivePackage} = await isShowSceneAllowed(initData.configuratorId, this.$sdkConnector, this.$analytics);
      if (!isCorrectDomain) {
        this.isCorrectDomain = false;
      }
      if (!hasActivePackage) {
        this.hasActivePackage = false;
      }
      if (!isCorrectDomain || !hasActivePackage) {
        removeLoadingScreen();
      }
    },
    initSceneContainer() {
      // we do not await on purpose, to know why
      // see: https://gitlab.com/roomle/web/roomle-ui/-/merge_requests/358#note_500157532
      this._checkAccessRights();
      if (!this.$refs.sceneContainer) {
        if (!this.isCorrectDomain) {
          this.$analytics.ga.trackWrongDomain();
        }
        if (!this.hasActivePackage) {
          this.$analytics.ga.trackNoActivePackage();
        }
        return;
      }
    },
  },
});
