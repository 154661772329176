

























import Vue from 'vue';
// @ts-ignore
import SvgMagnifier from '@/common/svgs/magnifier.svg';
// @ts-ignore
import SvgClose from '@/common/svgs/close.svg';
import {throttle} from '@/common/utils/helper';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
export default Vue.extend({

  components: {
    SvgIcon,
  },

  beforeDestroy() {
    this.$store.commit(UI_STATE_MUTATIONS.SET_USER_INITIATED_SEARCH, false);
  },

  computed: {
    icons(): object {
      return {
        SvgMagnifier,
        SvgClose,
      };
    },
  },

  methods: {
    onInput(event: Event) {
      throttle(() => this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_SEARCH, (event.target as HTMLInputElement).value))();
    },
    onFocus() {
      const input = this.$el.querySelector<HTMLInputElement>('input')!;
      if (!this.$store.state.uiState.gridViewSearch) {
        input.focus();
      } else {
        this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_SEARCH, null);
        input.focus();
      }
    },
    onCursorInInput() {
      this.$store.commit(UI_STATE_MUTATIONS.SET_USER_INITIATED_SEARCH, true);
    },
    onCursorLeavesInput() {
      this.$store.commit(UI_STATE_MUTATIONS.SET_USER_INITIATED_SEARCH, false);
    },
  },

  destroyed() {
    this.$store.commit(UI_STATE_MUTATIONS.SET_USER_INITIATED_SEARCH, false);
    this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_SEARCH, null);
  },

});
