










import Vue from 'vue';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
// @ts-ignore
import SvgArrow from '@/common/svgs/arrow.svg';
export default Vue.extend({
  components: {
    SvgIcon,
  },
  computed: {
    icons(): object {
      return {SvgArrow};
    },
  },
});
