












import Vue, {PropOptions} from 'vue';
import {KernelValue, UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {GridViewElement, GridViewGroup, toGridViewElements} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import {uuid} from '@/common/utils/helper';
import GridView from '@/configurator/components/grid-view/GridView.vue';

export default Vue.extend({

  components: {
    GridView,
  },

  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
    isExpanded: Boolean,
    onExpand: Function,
    onCollapse: Function,
  },

  computed: {

    allElements(): GridViewElement[] {
      return toGridViewElements(this.parameter.validValues, 'value');
    },

    selectedElement(): GridViewElement {
      return this.allElements.find((el) => (el.payload as KernelValue).value === this.parameter.value) as GridViewElement;
    },
    normalizedGroups(): GridViewGroup[] {
      return [{
        label: this.parameter.label,
        id: uuid(),
        elements: this.allElements,
      }];
    },
  },

  methods: {
    async onSelect({key}: {key: string}) {
      await this.$sdkConnector.setParameter(this.parameter, key);
    },
  },

});
