export const sort = (groups: any) => {
  const sortArray: string|string[] = [
    'vallone:como_steel',
    'vallone:como_gold',
    'vallone:como_smoothbronze',
    'vallone:como_smoothbronze_round',
    'vallone:como_gunmetal',

    'vallone:lax_h_hb3_black',
    'vallone:lax_h_hw5_white',
    'vallone:lax_h_hs1_silver',
    'vallone:lax_h_hg4_gold',
    'vallone:lax_h_hc2_copper',

    'vallone:rovere_naturale',
    'vallone:rovere_safari',
    'vallone:rovere_termocotto',
    'vallone:legno_walnut_naturale',

    'vallone:bianco_statuario_matt',
    'vallone:bianco_statuario_glossy',
    'vallone:oro_venato_matt',
    'vallone:oro_venato_glossy',
    'vallone:desir_soft_matt',
    'vallone:desir_soft_glossy',
    'vallone:nero_marquina_matt',
    'vallone:nero_marquina_glossy',

    'vallone:bianco_matt',
    'vallone:bianco_glossy',
    'vallone:perla_matt',
    'vallone:perla_glossy',
    'vallone:seta_matt',
    'vallone:seta_glossy',
    'vallone:lino_matt',
    'vallone:lino_glossy',
    'vallone:corda_matt',
    'vallone:corda_glossy',
    'vallone:londra_matt',
    'vallone:londra_glossy',
    'vallone:efeso_matt',
    'vallone:efeso_glossy',
    'vallone:argrilla_matt',
    'vallone:argrilla_glossy',
    'vallone:pietra_matt',
    'vallone:pietra_glossy',
    'vallone:pamir_matt',
    'vallone:pamir_glossy',
    'vallone:bromo_matt',
    'vallone:bromo_glossy',
    'vallone:grafite_matt',
    'vallone:grafite_glossy',
    'vallone:nero_matt',
    'vallone:nero_glossy',
    'vallone:cervino_matt',
    'vallone:cervino_glossy',
    'vallone:dolomiti_matt',
    'vallone:dolomiti_glossy',
    'vallone:avana_matt',
    'vallone:avana_glossy',
    'vallone:lilla_matt',
    'vallone:lilla_glossy',
    'vallone:erica_matt',
    'vallone:erica_glossy',
    'vallone:cipria_matt',
    'vallone:cipria_glossy',
    'vallone:lavaredo_matt',
    'vallone:lavaredo_glossy',
    'vallone:salice_matt',
    'vallone:salice_glossy',
    'vallone:bosco_matt',
    'vallone:bosco_glossy',
    'vallone:alpaca_matt',
    'vallone:alpaca_glossy',
    'vallone:nero_igno',
    'vallone:bianco_kos',
    'vallone:beige_arizona',
    'vallone:grigio_londra',
    'vallone:grigio_efeso',
    'vallone:grigio_bromo',
    'vallone:limestone_clay',
    'vallone:limestone_amber',
    'vallone:limestone_oyster',
    'vallone:calce_grigio',
    'vallone:calce_antracite',
    'vallone:calce_nero',
    'vallone:cement',
    'vallone:fokos_rena',
    'vallone:fokos_roccia',
  ];

  const sortedGroups = groups.map((group: any) => {
    group?.elements.sort((a: any, b: any) => {
      if (sortArray.indexOf(a.key) > sortArray.indexOf(b.key)) {
        return 1;
      } else if (sortArray.indexOf(a.key) < sortArray.indexOf(b.key)) {
        return -1;
      }

      return 0;
    });

    if (group.elements.length === 1 && group.elements[0].key.toUpperCase().includes('EMPTY')) {
      return null;
    } else {
      return group;
    }
  });

  return sortedGroups.filter((group: any) => group);
};
