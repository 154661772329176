















import Vue, {VueConstructor} from 'vue';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
// @ts-ignore
import SvgPlaceholder from '@/common/svgs/placeholder.svg';

export default (Vue as VueConstructor<Vue & {
  $refs: {
    image: HTMLImageElement,
  },
}>).extend({
  components: {
    SvgIcon,
  },

  props: {
    src: String,
    alt: String,
  },

  data() {
    return {
      hasImage: true,
    };
  },

  mounted() {
    if (!this.$props.src) {
      this.hasImage = false;
      return;
    }
    const image = this.image;
    if (!image) {
      return;
    }
    image.onerror = () => this.hasImage = false;
  },

  destroyed() {
    const image = this.image;
    if (!image) {
      return;
    }
    image.onerror = () => undefined;
  },

  computed: {
    icons(): object {
      return {
        SvgPlaceholder,
      };
    },
    image(): HTMLImageElement {
      return this.$refs.image;
    },
  },
});
