
























import Vue from 'vue';
import SearchInput from '@/configurator/components/utils/SearchInput.vue';
// @ts-ignore
import SvgDots from '@/common/svgs/dots.svg';
// @ts-ignore
import SvgList from '@/common/svgs/list.svg';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
export default Vue.extend({

  components: {
    SvgIcon,
    SearchInput,
  },

  computed: {
    icons(): object {
      return {
        SvgList,
        SvgDots,
      };
    },
  },

  methods: {
    toggleListDisplayType(switchToList: boolean) {
      if (this.$store.state.uiState.showAllAsList === switchToList) {
        return;
      }
      this.$store.commit(UI_STATE_MUTATIONS.TOGGLE_LIST_DISPLAY_TYPE);
    },
  },

});
