import { render, staticRenderFns } from "./WordWrap.vue?vue&type=template&id=21f42bbe&scoped=true&"
import script from "./WordWrap.vue?vue&type=script&lang=ts&"
export * from "./WordWrap.vue?vue&type=script&lang=ts&"
import style0 from "./WordWrap.vue?vue&type=style&index=0&id=21f42bbe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f42bbe",
  null
  
)

export default component.exports