import { render, staticRenderFns } from "./CollapsedView.vue?vue&type=template&id=28692b66&scoped=true&"
import script from "./CollapsedView.vue?vue&type=script&lang=ts&"
export * from "./CollapsedView.vue?vue&type=script&lang=ts&"
import style0 from "./CollapsedView.vue?vue&type=style&index=0&id=28692b66&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28692b66",
  null
  
)

export default component.exports