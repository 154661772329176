import {Nullable} from '@/common/utils/types';

let currentFontSize: Nullable<number> = null;

window.addEventListener('resize', () => currentFontSize = null);

export const getCurrentFontSize = (): number => {
  if (currentFontSize) {
    return currentFontSize;
  }
  currentFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize || '16');
  return currentFontSize;
};
