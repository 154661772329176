









import Vue, {VueConstructor} from 'vue';
import SceneContainer from '@/configurator/components/SceneContainer.vue';

export default (Vue as VueConstructor<Vue & {
  $refs: {
    configuratorContainer: HTMLElement,
  },
}>).extend({
  components: {
    SceneContainer,
  },
  mounted() {
    this.$sdkConnector.canvasElementReady(this.$refs.configuratorContainer);
  },

});
