

















import Vue from 'vue';

export default Vue.extend({
  name: 'WordWrap.vue',

  props: {
    text: String,
    wrapAt: {
      type: Number,
      default: 11,
    },
    lines: {
      type: Number,
      default: 3, // valid from 1 to 3
    },
  },

  computed: {
    wrappedLines() {
      const croppedLines = {
        line1: '',
        line2: '',
        line3: '',
      };
      const lines = this.$props.lines;
      const line1 = this.setLine(this.$props.text);
      croppedLines.line1 = line1.line;

      if (!line1.remaining.length) {
        return croppedLines;
      }

      if (lines === 1) {
        croppedLines.line1 += ' ' + line1.remaining;
        return croppedLines;
      }

      const line2 = this.setLine(line1.remaining);
      croppedLines.line2 = line2.line;

      if (!line2.remaining.length) {
        return croppedLines;
      }

      if (lines === 2) {
        croppedLines.line2 += ' ' + line2.remaining;
        return croppedLines;
      }

      const line3 = this.setLine(line2.remaining);
      croppedLines.line3 = line3.line;
      return croppedLines;
    },
  },

  methods: {
    setLine(text: string) {
      const whitespace = text ? text.indexOf(' ', this.wrapAt) : -1;

      return {
        line: whitespace >= 0 ? text.substr(0, whitespace) : text,
        remaining: whitespace >= 0 ? text.substr(whitespace + 1) : '',
      };
    },
  },
});
