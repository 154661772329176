



























import Vue from 'vue';
import ArrowButton from '@/configurator/components/utils/ArrowButton.vue';
import {UI_STATE_ACTIONS} from '@/configurator/store/ui-state';
// @ts-ignore
// import styles because getComputedStyle is very expensive
// ts-ignore because there is a bug with css-modules in vue cli and typescript
import {interactionTiming} from '@/configurator/components/utils/-utils/InteractionContainer.module.scss';
// @ts-ignore
// import styles because getComputedStyle is very expensive
// ts-ignore because there is a bug with css-modules in vue cli and typescript
import {ConfiguratorResizer} from '@/configurator/components/utils/-utils/ConfiguratorResizer.ts';
import {animationDurationInMsToFloat} from '@/common/utils/dom';
import {CanvasOffset} from '@roomle/web-sdk/lib/definitions/common-core/src/common-interfaces';
import {Nullable} from '@/common/utils/types';
import {throttle} from '@/common/utils/helper';

export default Vue.extend({
  name: 'InteractionsContainer.vue',

  components: {
    ArrowButton,
  },

  data() {
    return {
      _resizer: null as Nullable<ConfiguratorResizer>,
    };
  },

  methods: {
    toggle() {
      this.$store.dispatch(UI_STATE_ACTIONS.SET_INTERACTIONS_EXPANDED, !this.$store.state.uiState.interactionsExpanded);
    },

    _getCameraOffset(): CanvasOffset {
      const {isDesktop, isLandscape} = this.$store.state.uiState;
      const {groups} = this.$store.state.coreData;
      const parentWidth = this.$el.parentElement!.clientWidth;
      const width = this.$el.clientWidth;
      const parentHeight = this.$el.parentElement!.clientHeight;
      const height = this.$el.clientHeight;
      return ConfiguratorResizer.getCameraOffset(parentWidth, width, parentHeight, height,
        this.isFullscreen, this.isExpanded, isDesktop, isLandscape, groups?.length > 0);
    },
    _handleCameraOffset(..._args: any[]) {
      return; // just here to override it later
    },
  },

  async mounted() {
    const api = await this.$sdkConnector.api;
    this._resizer = new ConfiguratorResizer(api);
    this._handleCameraOffset = throttle(() => {
      this._resizer?.animateOffset(this._getCameraOffset(), 0);
    }).bind(this);
    this._handleCameraOffset();
    window.addEventListener('resize', this._handleCameraOffset);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._handleCameraOffset);
  },

  computed: {
    isDesktop() {
      return this.$store.state.uiState.isDesktop;
    },
    isExpanded(): boolean {
      return this.$store.state.uiState.interactionsExpanded && !this.$store.state.uiState.isFullscreen;
    },
    isFullscreen(): boolean {
      return this.$store.state.uiState.isFullscreen;
    },
  },

  watch: {
    isExpanded: {
      async handler() {
        this._resizer?.animateOffset(this._getCameraOffset(), animationDurationInMsToFloat(interactionTiming));
      },
    },
    isFullscreen: {
      async handler() {
        this._resizer?.animateOffset(this._getCameraOffset(), animationDurationInMsToFloat(interactionTiming));
      },
    },
  },
});
