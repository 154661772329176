import {CanvasOffset} from '@roomle/web-sdk/lib/definitions/common-core/src/common-interfaces';
import {Nullable} from '@/common/utils/types';
import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
import {easeInOutBezier} from '@/common/utils/bezier';
import {deepCopy} from '@/common/utils/helper';
import {percentInStringToFloat, remToPixel} from '@/common/utils/dom';
// @ts-ignore
import {interactionCollapsedHeight, interactionContainerHeight} from '@/configurator/components/utils/-utils/InteractionContainer.module.scss';
import {getCurrentFontSize} from '@/common/utils/font-size-detector';

export interface ConfiguratorResizerCallback {
  onStart: () => void;
  onProgress: (remaining: CanvasOffset) => void;
  onFinished: () => void;
}

export class ConfiguratorResizer {

  // tslint:disable-next-line:max-line-length
  public static getCameraOffset(parentWidth: number, width: number, parentHeight: number, height: number, isFullscreen: boolean, isExpanded: boolean, isDesktop: boolean, isLandscape: boolean, hasGroups: boolean): CanvasOffset {
    const offset: CanvasOffset = {left: 0, right: 1, bottom: 0, top: 1};
    if (isDesktop) {
      if (isExpanded && !isFullscreen) {
        offset.right = 1 - (width / parentWidth);
      }
    } else {
      if (hasGroups) {
        offset.top = 0.9;
      }
      let containerHeight = height;
      if (!containerHeight) {
        containerHeight = parentHeight;
      }
      const heightExpandedInPercent = percentInStringToFloat(interactionContainerHeight);
      const stageHeightInPixel = Math.floor(containerHeight / heightExpandedInPercent);
      const heightCollapsedInPercent = remToPixel(interactionCollapsedHeight, getCurrentFontSize()) / stageHeightInPixel;
      // is fullscreen or is mobile landscape (always fullscreen)
      if (isFullscreen || (!isDesktop && isLandscape)) {
        offset.bottom = 0;
      } else if (isExpanded) {
        offset.bottom = heightExpandedInPercent;
      } else {
        offset.bottom = heightCollapsedInPercent;
      }
    }
    return offset;
  }

  private _api: Nullable<RoomleConfigurator> = null;
  private _targetOffset: Nullable<CanvasOffset> = null;
  private _startOffset: Nullable<CanvasOffset> = null;
  private _distance: Nullable<CanvasOffset> = null;
  private _startTime: number = 0;
  private _duration: number = 0;

  constructor(api: RoomleConfigurator) {
    this._api = api;
  }

  public animateOffset(target: CanvasOffset, duration: number, callback?: ConfiguratorResizerCallback) {
    this._targetOffset = target;
    this._duration = duration;
    this._startTime = Date.now();
    if (this._api) {
      this._startOffset = this._api.getCameraOffset();
      this._distance = this._getRemainingDistance();
      callback?.onStart();
      this._resize(callback);
    }
  }

  private async _resize(callback?: ConfiguratorResizerCallback) {
    requestAnimationFrame(async () => {
      const remainingDistance = this._multiplyScalar(deepCopy(this._distance) as CanvasOffset, this._easeInOutBezier());
      callback?.onProgress(remainingDistance);

      const offset = JSON.parse(JSON.stringify(this._startOffset));
      this._addOffsets(offset, remainingDistance);

      this._api!.setCameraOffset(offset);
      if (this._startTime + this._duration > Date.now()) {
        this._resize(callback);
      } else {
        callback?.onFinished();
      }
    });
  }

  private _addOffsets(offset1: CanvasOffset, offset2: CanvasOffset): CanvasOffset {
    const {bottom, left, right, top} = offset2;
    offset1.left += left;
    offset1.right += right;
    offset1.bottom += bottom;
    offset1.top += top;
    return offset1;
  }

  private _multiplyScalar(offset: CanvasOffset, scalar: number): CanvasOffset {
    offset.left *= scalar;
    offset.right *= scalar;
    offset.bottom *= scalar;
    offset.top *= scalar;
    return offset;
  }

  private _easeInOutBezier() {
    return easeInOutBezier((Date.now() - this._startTime) / this._duration);
  }

  private _getRemainingDistance(): CanvasOffset {
    const remainingDistance: CanvasOffset = {left: 0, top: 0, right: 0, bottom: 0};
    if (!this._startOffset || !this._targetOffset) {
      console.warn('start or target offset not set');
      return remainingDistance;
    }

    const {bottom, left, right, top} = this._targetOffset;
    const {bottom: bottom1, left: left1, right: right1, top: top1} = this._startOffset;
    remainingDistance.left = left - left1;
    remainingDistance.right = right - right1;
    remainingDistance.bottom = bottom - bottom1;
    remainingDistance.top = top - top1;

    return remainingDistance;
  }
}
