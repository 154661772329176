

































































































import Vue from 'vue';
// @ts-ignore
import SvgClose from '@/common/svgs/close.svg';
// @ts-ignore
import SvgBowlOnTop from '@/common/svgs/custom/dist/bowl_on_top.svg';
// @ts-ignore
import SvgChevronDown from '@/common/svgs/chevron-down.svg';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import {API_BASE} from '@/common/utils/api-url';
import {getGeneralCustomConfig} from '@/common/utils/custom-config';

interface CollectionGroup {
  title: string;
  collections: Collection[];
}

interface Collection {
  title: string;
  image: string;
  presets: [];
}

export default Vue.extend({
  data() {
    return {
      isOpen: false,
      collectionGroups: [] as CollectionGroup[],
      selectedCollectionGroup: undefined as CollectionGroup | undefined,
      selectedCollection: undefined as Collection | undefined,
    };
  },

  async mounted() {
    const response = await fetch(`${API_BASE}/api/v1/roomle/presets`, {
      method: 'GET',
      credentials: 'include',
    });
    const json = await response.json();

    if (json.success) {
      this.collectionGroups = json.data;
    }
  },

  components: {
    SvgIcon,
  },

  computed: {
    icons(): object {
      return {
        SvgBowlOnTop,
        SvgChevronDown,
        SvgClose,
      };
    },
  },

  methods: {
    openCollection() {
      this.isOpen = true;
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
    },
    closeCollection() {
      this.isOpen = false;
      window.parent.postMessage('enable overflow', API_BASE);
      this.selectedCollectionGroup = undefined;
      this.selectedCollection = undefined;
    },
    toggleCollectionGroup(collectionGroup: CollectionGroup) {
      if (this.selectedCollectionGroup === collectionGroup) {
        this.selectedCollectionGroup = undefined;
        this.selectedCollection = undefined;
      } else {
        this.selectedCollectionGroup = collectionGroup;
        this.selectedCollection = undefined;

        if (collectionGroup.collections.length <= 1) {
          this.selectedCollection = collectionGroup.collections[0];
        }
      }
    },
    toggleCollection(collection: Collection) {
      this.selectedCollection = collection;
    },
    changeConfig(e: Event) {
      e.preventDefault();
      window.parent.postMessage({action: 'redirect config', url: (e.currentTarget as HTMLLinkElement | null)?.dataset.url}, API_BASE);
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
  },
});
