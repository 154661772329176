

















































































































































































import Vue from 'vue';
import TabbedNav from '@/configurator/components/collection-view/-utils/TabbedNav.vue';
import HeightContainer from '@/configurator/components/utils/HeightContainer.vue';
// components which are added dynamically to the DOM depending on the data we get
import MaterialParameter from '@/configurator/components/parameters/types/MaterialParameter.vue';
import CustomMaterialParameter from '@/configurator/components/parameters/types/CustomMaterialParameter.vue';
import UnknownParameter from '@/configurator/components/parameters/types/UnknownParameter.vue';
import OptionsParameter from '@/configurator/components/parameters/types/OptionsParameter.vue';
import ThumbnailsParameter from '@/configurator/components/parameters/types/ThumbnailsParameter.vue';
import RangeParameter from '@/configurator/components/parameters/types/RangeParameter.vue';
import SVGParameter from '@/configurator/components/parameters/types/SVGParameter.vue';
import ImageParameter from '@/configurator/components/parameters/types/ImageParameter.vue';
import RoundImageParameter from '@/configurator/components/parameters/types/RoundImageParameter.vue';
import PossibleChildren from '@/configurator/components/addons/PossibleChildren.vue';
import ProductVariant from '@/configurator/components/parameters/types/ProductVariant.vue';
// -- end -- dynamically components
import {snakeCase} from '@/common/utils/helper';
import {CollectionViewElement, CollectionViewElementType} from '@/configurator/components/collection-view/-utils/types';
import {Nullable} from '@/common/utils/types';
import {UI_STATE_ACTIONS, UI_STATE_GETTERS, UI_STATE_MUTATIONS} from '../../store/ui-state';
import BackButton from '@/configurator/components/collection-view/-utils/BackButton.vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {OVERLAYS} from '@/configurator/components/-utils/overlays';

import {getFieldCustomConfigByKey, getGeneralCustomConfig} from '@/common/utils/custom-config';
import ProductFooter from '@/configurator/components/collection-view/-utils/ProductFooter.vue';
import {CORE_DATA_GETTERS} from '@/configurator/store/core-data';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgTooltipPlus from '@/common/svgs/custom/dist/common/tooltip-plus.svg';
import {iconByKey} from '@/common/utils/icons';
import tippy from 'tippy.js';

const EXPANDABLE_ELEMENT_TYPES: CollectionViewElementType[] = ['addon', 'variant'];
const EXPANDABLE_PARAMETERS = ['Material', 'Thumbnails'];

const dynamicComponents = {
  MaterialParameter,
  CustomMaterialParameter,
  OptionsParameter,
  ThumbnailsParameter,
  RangeParameter,
  SVGParameter,
  ImageParameter,
  RoundImageParameter,
  UnknownParameter,
  PossibleChildren,
  ProductVariant,
};

export default Vue.extend({

  components: {
    TabbedNav,
    BackButton, // : () => import('@/configurator/components/collection-view/-utils/BackButton.vue'),
    HeightContainer,
    NextParameterGroupButton: () => import('@/configurator/components/parameters/NextParameterGroupButton.vue'),
    ProductFooter,
    SvgIcon,
    SvgTooltipPlus,
    ...dynamicComponents,
  },

  props: {
    onToggleExpand: Function,
  },

  watch: {
    '$store.state.uiState.isLoadingInProgress'() {
      if (!this.$store.state.uiState.isLoadingInProgress) {
        this.setTippy();
      }
    },
  },

  computed: {
    icons(): object {
      return {
        SvgTooltipPlus,
      };
    },
    showNextGroupButton(): boolean {
      return this.$store.state.uiState.isDesktop && (!this.isGridViewExpanded || this.expandedOnDefault);
    },
    showBack(): boolean {
      if (!this.$store.state.uiState.isDesktop) {
        return false;
      }
      const {collectionViewShowsDetails, onlyShowAddons, showVariants} = this.$store.state.uiState;
      return collectionViewShowsDetails || onlyShowAddons || showVariants;
    },
    helpText(): Nullable<string> {
      if (!this.hasElementsToShow && !this.$store.state.uiState.isLoadingInProgress) {
        return (this as any).t.t('params.no-elements');
      }
      const {onlyShowAddons, showVariants, isDesktop} = this.$store.state.uiState;
      if (isDesktop && onlyShowAddons) {
        return (this as any).t.t('addons.hint');
      }
      if (isDesktop && showVariants) {
        return (this as any).t.t('variants.help');
      }
      return null;
    },
    showTabbedNav(): boolean {
      return false;
      // return !this.$store.state.uiState.collectionViewShowsDetails && !this.$store.state.uiState.isDesktop;
    },
    isGridViewExpanded(): boolean {
      const {collectionViewShowsDetails} = this.$store.state.uiState;
      if (this.expandedOnDefault) {
        return true;
      }
      return collectionViewShowsDetails;
    },
    expandedOnDefault(): boolean {
      if (!this.$store.state.uiState.isDesktop) {
        return false;
      }
      if (this.allElements?.length !== 1) {
        return false;
      }
      const firstElement = this.allElements[0];
      if (EXPANDABLE_ELEMENT_TYPES.includes(firstElement.type)) {
        return true;
      }
      if (EXPANDABLE_PARAMETERS.includes((firstElement.payload as UiKernelParameter).uiType)) {
        return true;
      }
      return false;
    },
    selectedTab(): Nullable<CollectionViewElement> {
      const selected = this.$store.state.uiState.selectedCollectionViewElement;
      if (!this.allElements.length) {
        return null;
      }
      if (!selected) {
        return this.allElements[0];
      }
      const toSelect = this.allElements.find((element) => element.key === selected.key);
      return !toSelect ? this.allElements[0] : toSelect;
    },
    elements(): CollectionViewElement[] {
      if (!this.allElements.length) {
        return [];
      }
      const {selectedCollectionViewElement} = this.$store.state.uiState;
      if (this.$store.state.uiState.collectionViewShowsDetails) {
        // Find is needed to create a re-render
        const elementToSelect = this.allElements.find((element) => element.key === selectedCollectionViewElement.key);
        return (elementToSelect) ? [elementToSelect] : [];
      }
      if (this.$store.state.uiState.isDesktop) {
        return this.allElements;
      }
      return this.allElements;
      // if (!selectedCollectionViewElement) {
      //   return [this.allElements[0]];
      // }
      // const selectedElement = this.allElements.find((element) => element.key === selectedCollectionViewElement.key);
      // return selectedElement ? [selectedElement] : [];
    },
    allElements(): CollectionViewElement[] {
      setTimeout(() => {
        this.setTippy();
      }, 100);
      let collectionViewElements;
      if (this.$store.state.uiState.onlyShowAddons) {
        collectionViewElements = this.$store.getters[UI_STATE_GETTERS.COLLECTION_VIEW_ADDON_ELEMENTS] as CollectionViewElement[];
      } else if (this.$store.state.uiState.showVariants) {
        collectionViewElements = this.$store.getters[UI_STATE_GETTERS.COLLECTION_VIEW_VARIANTS] as CollectionViewElement[];
      } else {
        collectionViewElements = this.$store.getters[UI_STATE_GETTERS.COLLECTION_VIEW_ELEMENTS] as CollectionViewElement[];
      }
      const missingLabelFallbackKeys: {[key in CollectionViewElementType]: string} = {
        addon: 'addon-fallback',
        parameter: 'parameter-fallback',
        variant: 'variant-fallback',
      };
      const elementCountWithoutLabel: {[key in CollectionViewElementType]: number} = {addon: 0, parameter: 0, variant: 0};
      let hasUpdatedSubElements = false;
      collectionViewElements.forEach((element) => {
        const missingLabelFallbackKey = missingLabelFallbackKeys[element.type];
        if (!element.label || element.label.startsWith(missingLabelFallbackKey)) {
          console.warn('[ContentWarning]', 'type', element.type, 'key', element.key, 'has no label');
          elementCountWithoutLabel[element.type]++;
          const translationKey = missingLabelFallbackKey;
          const suffix = (elementCountWithoutLabel[element.type] > 1) ? ' ' + elementCountWithoutLabel[element.type] : '';
          element.label = (this as any).t.t(translationKey) + suffix;
          hasUpdatedSubElements = true;
        }
      });
      // only create a new array if we need those fallback labels
      // I do not want to add a performance degradation for all
      // working content :-)
      return hasUpdatedSubElements ? [...collectionViewElements] : collectionViewElements;
    },
    hasElementsToShow(): boolean {
      return this.allElements.length > 0;
    },
    webTeaser(): string {
      const attributes = this.$store.getters[CORE_DATA_GETTERS.ATTRIBUTES];
      return attributes.web_teaser ? attributes.web_teaser : getGeneralCustomConfig().partListDescription ? getGeneralCustomConfig().partListDescription : '';
    },
    webDescription(): string {
      const attributes = this.$store.getters[CORE_DATA_GETTERS.ATTRIBUTES];
      return attributes.web_description ? attributes.web_description : this.webTeaser;
    },
  },

  methods: {
    isDisabled(collectionViewElement: CollectionViewElement) {
      const enabled = collectionViewElement.payload?.enabled;
      return (typeof enabled === 'boolean' && enabled === false) ? true : false;
    },
    showDisabledMessage() {
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.ELEMENT_DISABLED, open: true});
    },
    getTemplate(collectionViewElement: CollectionViewElement, useSnakeCase: boolean = false) {
      let domComponentName = '';
      if (collectionViewElement.type === 'addon') {
        domComponentName = 'PossibleChildren';
      } else if (collectionViewElement.type === 'variant') {
        domComponentName = 'ProductVariant';
      } else {
        domComponentName = collectionViewElement.payload.uiType + 'Parameter';
        // custom parameters
        if (
          this.getCustomType(collectionViewElement) === 'SVG'
          || this.getCustomType(collectionViewElement) === 'Image'
          || this.getCustomType(collectionViewElement) === 'RoundImage'
          || this.getCustomType(collectionViewElement) === 'CustomMaterial'
        ) {
          domComponentName = this.getCustomType(collectionViewElement) + 'Parameter';
        }
        if (!Object.keys(dynamicComponents).find((componentName) => componentName === domComponentName)) {
          if (domComponentName === 'BooleanParameter') {
            return 'OptionsParameter';
          }
          domComponentName = 'UnknownParameter';
        }
      }
      return (!useSnakeCase) ? domComponentName : snakeCase(domComponentName);
    },
    getCustomType(collectionViewElement: CollectionViewElement): string {
      const customConfig = getFieldCustomConfigByKey(String(collectionViewElement.payload.key));
      if (customConfig && customConfig.type) {
        return customConfig.type;
      } else {
        return String(collectionViewElement.payload.uiType);
      }
    },
    onGoBack() {
      const wasShowDetailsBefore = this.$store.state.uiState.collectionViewShowsDetails;
      this.$store.commit(UI_STATE_MUTATIONS.COLLECTION_VIEW_ELEMENT_EXPANDED, false);
      const {onlyShowAddons, showVariants} = this.$store.state.uiState;
      if (onlyShowAddons && !wasShowDetailsBefore) {
        this.$store.commit(UI_STATE_MUTATIONS.SHOW_ALL_COLLECTION_VIEW_ELEMENTS);
      }
      if (showVariants) {
        this.$store.dispatch(UI_STATE_ACTIONS.RESET_COLLECTION_VIEW);
      }
      this.$sdkConnector.api.then((configurator) => configurator.cancelDockings());
    },
    onSelectTab(collectionViewElement: CollectionViewElement) {
      this.$store.commit(UI_STATE_MUTATIONS.SET_SELECTED_COLLECTION_VIEW_ELEMENT, collectionViewElement);
    },
    onExpandGridView(collectionViewElement: CollectionViewElement) {
      return () => {
        if (!this.$store.state.uiState.interactionsExpanded) {
          this.onToggleExpand();
        }
        this.$store.commit(UI_STATE_MUTATIONS.SET_SELECTED_COLLECTION_VIEW_ELEMENT, collectionViewElement);
        this.$store.commit(UI_STATE_MUTATIONS.COLLECTION_VIEW_ELEMENT_EXPANDED, true);
      };
    },
    onSelect() {
      // tslint
    },
    onCollapse() {
      if (!this.$store.state.uiState.isDesktop) {
        this.$store.dispatch(UI_STATE_ACTIONS.SET_INTERACTIONS_EXPANDED, false);
      }
      this.$store.commit(UI_STATE_MUTATIONS.COLLECTION_VIEW_ELEMENT_EXPANDED, false);
    },
    isLastElement(index: number): boolean {
      return index === this.elements.length - 1;
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
    getActiveState(configKey: string): boolean {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return (configKey && customConfig && customConfig.active === false) ? false : true;
    },
    getItemWidth(configKey: string): string {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return (configKey && customConfig && customConfig.width) ? customConfig.width : '100';
    },
    getItemCSS(configKey: string, fullWidth = false): string {
      const customConfig = getFieldCustomConfigByKey(configKey);
      let css = '';
      if (configKey && customConfig && customConfig.order) {
        css = css + 'order: ' + customConfig.order + '; ';
      }
      if (configKey && customConfig && customConfig.width && !fullWidth) {
        css = css + 'width: calc(' + customConfig.width + '% - 30px); ';
      } else {
        css = css + 'width: 100%; ';
      }
      return css;
    },
    getItemLabel(collectionViewElement: CollectionViewElement, raw: boolean = false): string|null {
      const customConfig = getFieldCustomConfigByKey(String(collectionViewElement.payload.key));
      if (!raw && customConfig && customConfig.label && (customConfig.label === 'L1' || customConfig.label === 'L2')) {
        return null;
      }
      if (customConfig && customConfig.label) {
        return customConfig.label;
      } else if (customConfig && customConfig.label === null) {
        return null;
      } else {
        return collectionViewElement.label;
      }
    },
    getItemDescription(collectionViewElement: CollectionViewElement): string|null {
      const customConfig = getFieldCustomConfigByKey(String(collectionViewElement.payload.key));
      if (customConfig && customConfig.description) {
        return customConfig.description;
      } else {
        return null;
      }
    },
    getItemTooltip(collectionViewElement: CollectionViewElement): any {
      const customConfig = getFieldCustomConfigByKey(String(collectionViewElement.payload.key));
      if (customConfig && customConfig.tooltip) {
        return {
          tooltip: customConfig.tooltip,
          tooltip_direction: customConfig.tooltip_direction || null,
        };
      } else {
        return null;
      }
    },
    getBeforeContent(configKey: string): any {
      const customConfig = getFieldCustomConfigByKey(configKey);
      if (configKey && customConfig && customConfig.before_active === true) {
        return {
          headline: customConfig.before_headline,
          description: customConfig.before_description,
          tooltip: customConfig.before_tooltip,
          tooltip_direction: customConfig.before_tooltip_direction,
          image: customConfig.before_image,
        };
      }
      return null;
    },
    getDisabledStatus(configKey: string): boolean {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return (configKey && customConfig && customConfig.disabled) ? true : false;
    },
    getVisuallyHiddenStatus(configKey: string): boolean {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return (configKey && customConfig && customConfig.visually_hidden) ? true : false;
    },
    icon(valueKey: string): Nullable<string> {
      return iconByKey(valueKey);
    },
    getHelp(configKey: string): any|null {
      const customConfig = getFieldCustomConfigByKey(configKey);
      if (configKey && customConfig && customConfig.help_active) {
        return {
          target: customConfig.help_target || null,
          pretitle: customConfig.help_pretitle || null,
          title: customConfig.help_title || null,
          subtitle: customConfig.help_subtitle || null,
          content: customConfig.help_content || null,
        };
      } else {
        return null;
      }
    },
    help(configKey: string) {
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.HELP, open: true, payload: this.getHelp(configKey)});
    },
    getExtraClasses(configKey: string): string {
      const customConfig = getFieldCustomConfigByKey(configKey);
      let extraClasses: string = '';
      if (configKey && customConfig && customConfig.has_custom_material) {
        extraClasses += 'round-image-with-custom-material';
      }
      if (configKey && customConfig && customConfig.type === 'CustomMaterial') {
        extraClasses += 'custom-material ';
      }
      return extraClasses;
    },
    getTrimUnnecessaryInfo(configKey: string): boolean {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return configKey && customConfig && customConfig.trim_unnecessary_info ? true : false;
    },
    getMaterialTooltip(configKey: string): any {
      const customConfig = getFieldCustomConfigByKey(configKey);
      if (configKey && customConfig && customConfig.tooltip_materials) {
        return customConfig.tooltip_materials;
      } else {
        return null;
      }
    },
    getHasBorderTop(configKey: string): any {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return configKey && customConfig && customConfig.has_border_top ? true : false;
    },
    getHasBorderBottom(configKey: string): any {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return configKey && customConfig && customConfig.has_border_bottom ? true : false;
    },
    getForceButtons(configKey: string): any {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return configKey && customConfig && customConfig.force_buttons ? true : false;
    },
    getKeepMaterialLabel(configKey: string): any {
      const customConfig = getFieldCustomConfigByKey(configKey);
      return configKey && customConfig && customConfig.keep_material_label ? true : false;
    },
    setTippy() {
      let tooltips: any[] | null = null;
      if (this.$refs.tooltip) {
        tooltips = Array.from(this.$refs.tooltip as unknown as NodeListOf<HTMLElement>);
      }
      tooltips?.forEach((tooltip) => {
        tippy(tooltip, {
          content: tooltip.dataset.tooltip,
          allowHTML: true,
          // hideOnClick: false,
          // trigger: 'click',
        });
      });
    },
  },

});
