












import Vue, {PropOptions} from 'vue';
import {UiPossibleChild, UiPossibleChildTag} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import GridView from '@/configurator/components/grid-view/GridView.vue';
import {GridViewElement, GridViewGroup, toGridViewElements} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';

export default Vue.extend({

  components: {
    GridView,
  },

  props: {
    addonTag: Object as PropOptions<UiPossibleChildTag>,
    isExpanded: Boolean,
    onExpand: Function,
    onCollapse: Function,
  },

  data() {
    return {
      groups: [] as UiPossibleChild[],
    };
  },

  computed: {
    allElements(): GridViewElement[] {
      return toGridViewElements(this.addonTag.possibleChildren, 'id');
    },
    normalizedGroups(): GridViewGroup[] {
      const id = this.addonTag.id;
      return [{id, label: this.addonTag.label as string, elements: this.allElements}];
    },
  },

  methods: {
    async onSelect(possibleChild: GridViewElement) {
      this.$store.commit(UI_STATE_MUTATIONS.SET_ACTIVE_ADDON, possibleChild);
      (await this.$sdkConnector.api as RoomleConfigurator).previewDockings(possibleChild.payload as UiPossibleChild);
    },
  },

});

