











import Vue, {PropOptions} from 'vue';
import GridView from '@/configurator/components/grid-view/GridView.vue';
import {GridViewElement, GridViewGroup, toGridViewElements} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {RapiTagForUi, RapiItem} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {OVERLAYS} from '@/configurator/components/-utils/overlays';

export default Vue.extend({

  components: {
    GridView,
  },

  props: {
    variants: Object as PropOptions<RapiTagForUi>,
    isExpanded: Boolean,
    onExpand: Function,
    onCollapse: Function,
  },

  data() {
    return {
      groups: [] as RapiTagForUi[],
    };
  },

  computed: {
    allElements(): GridViewElement[] {
      return toGridViewElements(this.variants.items, 'id');
    },
    normalizedGroups(): GridViewGroup[] {
      const id = this.variants.id as string;
      return [{id, label: this.variants.label as string, elements: this.allElements}];
    },
  },

  methods: {
    async onSelect(variant: GridViewElement) {
      const id = (variant.payload as RapiItem).id;
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.VARIANT_WARNING, open: true, payload: id});
    },
  },

});

