import {Analytics} from '@/common/plugins/analytics';
import {runOnIdle} from '@/common/utils/helper';
import {SdkConnector} from '@/configurator/business-logic/sdk-connector';

export interface AccessCheckResult {
  isCorrectDomain: boolean;
  hasActivePackage: boolean;
}

export const isShowSceneAllowed = async (configuratorId: string, sdkConnector: SdkConnector, analytics: Analytics): Promise<AccessCheckResult> => {
  return new Promise((resolve) => {
    runOnIdle(async () => {
      const result: AccessCheckResult = {isCorrectDomain: true, hasActivePackage: true};

      const isCorrectDomain = await sdkConnector.isCorrectConfiguratorDomain(configuratorId);
      if (!isCorrectDomain) {
        analytics?.ga.trackWrongDomain();
        result.isCorrectDomain = false;
      }
      const hasActivePackage = await sdkConnector.hasActivePackage();
      if (!hasActivePackage) {
        analytics?.ga.trackNoActivePackage();
        result.hasActivePackage = false;
      }
      resolve(result);
    });
  });
};
