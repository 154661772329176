













import Vue from 'vue';
import {UiState} from '@/configurator/store/ui-state';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
// @ts-ignore
import SvgDelete from '@/common/svgs/delete.svg';

export default Vue.extend({

  components: {
    SvgIcon,
  },

  computed: {
    icons() {
      return {
        SvgDelete,
      };
    },
  },

  methods: {
    deleteSelected() {
      const currentSelection = (this.$store.state.uiState as UiState).currentSelection;
      if (!currentSelection?.isRoot) {
        this.$sdkConnector.api.then((api) => api.requestDeleteComponent());
      }
    },
  },
});
