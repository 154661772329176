







































































































import Vue from 'vue';
import {getGeneralCustomConfig, getAttributesCustomConfig} from '@/common/utils/custom-config';
import {UI_BUTTON} from '@/configurator/embedding/types';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {OVERLAYS} from '../../-utils/overlays';

import {API_BASE} from '@/common/utils/api-url';
import SvgHeart from '@/common/svgs/custom/dist/common/heart.svg';
import SvgMessage from '@/common/svgs/custom/dist/common/message.svg';
import SvgShoppingBag from '@/common/svgs/custom/dist/common/shopping-bag.svg';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgMinus from '@/common/svgs/custom/dist/common/minus.svg';
import SvgPlus from '@/common/svgs/custom/dist/common/plus.svg';
import {createRoomleEntry} from '@/common/utils/create-roomle-entry';
import {CORE_DATA_MUTATIONS} from '@/configurator/store/core-data';

export default Vue.extend({
  components: {
    SvgIcon,
  },

  computed: {
    icons(): object {
      return {
        SvgHeart,
        SvgMessage,
        SvgShoppingBag,
        SvgMinus,
        SvgPlus,
      };
    },
  },

  methods: {
    getConfigAttributes(): any {
      const configAttributes = getAttributesCustomConfig();
      return configAttributes;
    },
    getGeneralConfig(): any {
      const customConfig = getGeneralCustomConfig();
      return customConfig;
    },
    getHidePrice(): boolean {
      return getGeneralCustomConfig().hidePrice;
    },
    async saveDraft() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.SAVEDRAFT);
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.SAVE_DRAFT, open: true});
    },
    async addToCart() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.ADDTOCART);
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.ADD_TO_CART, open: true});
    },
    async requestPrice() {
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage('open request price popup', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);

      const data = await createRoomleEntry(this.$sdkConnector, this.$store, 'request price', (this as any).t.t('part-list.selected-config'));

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'add entry request price popup',
            entryId: data.entryId,
            entryImage: data.entryImage,
            currency: this.$store.state.coreData.currencySymbol,
            price: this.$store.state.coreData.price,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage('error adding entry', API_BASE);
      }
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
    changeCount(change: string): void {
      if (change === 'down') {
        if (this.$store.state.coreData.count > 1) {
          this.$store.state.coreData.count = this.$store.state.coreData.count - 1;
        }
      } else if (change === 'up') {
        this.$store.state.coreData.count = this.$store.state.coreData.count + 1;
      }
    },
    async openEmailModal() {
      window.parent.postMessage('open sales email modal', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);

      const data = await createRoomleEntry(this.$sdkConnector, this.$store, 'sales email offer', (this as any).t.t('part-list.selected-config'));

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'add entry sales email modal',
            entryId: data.entryId,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage('error adding entry', API_BASE);
      }
    },
  },

});
