






















import Vue from 'vue';
import {UI_STATE_ACTIONS, UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
// @ts-ignore
import SvgCheck from '@/common/svgs/check.svg';

import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import {UI_BUTTON} from '@/configurator/embedding/types';

export default Vue.extend({
  components: {
    SvgIcon,
  },
  props: {
    onShowAddons: Function,
  },
  computed: {
    icons(): object {
      return {SvgCheck};
    },
  },
  methods: {
    onClick() {
      if (this.$store.state.uiState.onlyShowAddons) {
        this.$store.commit(UI_STATE_MUTATIONS.SHOW_ALL_COLLECTION_VIEW_ELEMENTS);
        this.$sdkConnector.api.then((configurator) => configurator.cancelDockings());
      } else {
        this.onShowAddons();
        this.$store.dispatch(UI_STATE_ACTIONS.RESET_COLLECTION_VIEW);
        this.$store.commit(UI_STATE_MUTATIONS.SHOW_ONLY_ADDONS);
      }
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.ADDONS);
      this.$sdkConnector.api.then((api) => api.cancelSelection());
    },
  },
});
