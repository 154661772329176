



















import Vue from 'vue';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {CORE_DATA_MUTATIONS} from '@/configurator/store/core-data';
import {API_BASE} from '@/common/utils/api-url';
import {getGeneralCustomConfig} from '@/common/utils/custom-config';

export default Vue.extend({
  props: {
    scrollThisContainer: Boolean,
    hideScrollbar: Boolean,
  },

  data() {
    return {
      group: this.$store.state.uiState.selectedGroup?.key,
    };
  },

  methods: {
    unsubscribe() { return; },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === UI_STATE_MUTATIONS.SET_SELECTED_GROUP) {
        if (mutation.payload.key !== this.$data.group) {
          this.$data.group = mutation.payload.key;
          setTimeout(() => {
            (this.$refs.scrollContainer as HTMLElement)?.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 100);
        }
      }
    });
  },

  mounted() {
    if ((this.$refs.scrollContainer as HTMLElement).classList.contains('hide-scrollbar')) {
      (this.$refs.scrollContainer as HTMLElement)?.addEventListener('scroll', (e) => {
        if (window.innerWidth >= 1024) {
          if (this.getLook() === 'default') {
            window.parent.postMessage('enable overflow', API_BASE);
          }
          this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_INTERACTION_SCROLLSTATE, 'top');
          return;
        }

        const target = e.target as HTMLElement;

        if (target?.scrollTop > 50) {
          // scroll pos down below 50px
          if (this.$store.state.coreData.scrollState === 'top') {
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 300);
          }
          this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_INTERACTION_SCROLLSTATE, 'scrolled');

          if (this.getLook() === 'default') {
            if (target?.scrollHeight - target?.scrollTop <= target?.clientHeight + 3) {
              // scrolled to the last 3px
              // setTimeout(() => {
              //   window.parent.postMessage('enable overflow', API_BASE);
              // }, 600);
            } else if (target?.scrollHeight - target?.scrollTop > target?.clientHeight + 3) {
              window.parent.postMessage('just disable overflow', API_BASE);
            }
          }
        } else {
          // scroll pos in the upper 50px
          if (this.$store.state.coreData.scrollState === 'scrolled') {
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 300);
          }
          this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_INTERACTION_SCROLLSTATE, 'top');
        }
      });
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  },
});
