


























import Vue, {VueConstructor, PropOptions} from 'vue';
// @ts-ignore
// import styles because getComputedStyle is very expensive
// ts-ignore because there is a bug with css-modules in vue cli and typescript
import {parameterSpacingRight, parameterButtonPadding} from '@/configurator/components/collection-view/-utils/CollectionViewTabbedNav.module.scss';
import {throttle} from '@/common/utils/helper';
import {hasHorizontalOverflow, isScrolledToRightEnd, lastVisibleElement, remToPixel} from '@/common/utils/dom';
import OverflowIndicator from '@/configurator/components/parameters/OverflowIndicator.vue';
import {getCurrentFontSize} from '@/common/utils/font-size-detector';
import {CollectionViewElement} from '@/configurator/components/collection-view/-utils/types.ts';
import {Nullable} from '@/common/utils/types';
import {UiCallbacks} from '@/configurator/business-logic/ui-callback';

export default (Vue as VueConstructor<Vue & {
  $refs: {
    tabbedNav: HTMLUListElement,
  },
}>).extend({

  components: {
    OverflowIndicator,
  },

  props: {
    onSelect: Function as PropOptions<(tab: CollectionViewElement) => void>,
    tabs: Array as PropOptions<CollectionViewElement[]>,
  },

  methods: {
    _calcThreshold() {
      const fontSizePx = getCurrentFontSize();
      return remToPixel(parameterSpacingRight, fontSizePx) + remToPixel(parameterButtonPadding, fontSizePx) + remToPixel('1', fontSizePx);
    },

    _checkOverflow() {
      if (isScrolledToRightEnd(this.$refs.tabbedNav, this._calcThreshold())) {
        this.isOverflow = false;
        return;
      }
      this.isOverflow = hasHorizontalOverflow(this.$refs.tabbedNav);
    },

    _checkScroll() {
      this.isOverflow = !isScrolledToRightEnd(this.$refs.tabbedNav, this._calcThreshold());
    },

    _resetScroll() {
      const {tabbedNav} = this.$refs;
      if (!tabbedNav) {
        // this can happen on initial switch from
        // desktop to mobile
        return;
      }
      tabbedNav.scrollLeft = 0;
      this._checkScroll();
    },

    scrollLeft() {
      const {tabbedNav} = this.$refs;
      const lastVisibleChild = lastVisibleElement(tabbedNav, this._calcThreshold());
      if (!lastVisibleChild) {
        return;
      }
      tabbedNav.scroll({left: lastVisibleChild.getBoundingClientRect().left + tabbedNav.scrollLeft, behavior: 'smooth'});
    },

    isSelected(tab: CollectionViewElement, selected: CollectionViewElement): boolean {
      return tab.key === selected.key;
    },

  },

  computed: {
    selectedTab(): Nullable<CollectionViewElement> {
      const selected = this.$store.state.uiState.selectedCollectionViewElement;
      if (!this.tabs.length) {
        return null;
      }
      if (!selected) {
        this._resetScroll();
        return this.tabs[0];
      }
      const toSelect = this.tabs.find((element) => element.key === selected.key);
      if (!toSelect) {
        this._resetScroll();
        return this.tabs[0];
      }
      return toSelect;
    },
  },

  mounted() {
    this._checkOverflow = throttle(this._checkOverflow).bind(this);
    this._checkScroll = throttle(this._checkScroll).bind(this);
    window.addEventListener('resize', this._checkOverflow);
    const tabbedNav = this.$refs.tabbedNav;
    if (tabbedNav) {
      tabbedNav.addEventListener('scroll', this._checkScroll);
      this._checkOverflow();
    }
  },

  created() {
    this.$data._uiCallbacks.onSelectionChange = this._resetScroll.bind(this);
    this.$data._uiCallbacks.onSelectionCancel = this._resetScroll.bind(this);
    this.$sdkConnector.addUiCallback(this.$data._uiCallbacks);
  },

  beforeDestroy() {
    if (this.$data._uiCallbacks) {
      this.$sdkConnector.removeUiCallback(this.$data._uiCallbacks);
    }
    window.removeEventListener('resize', this._checkOverflow);
    this.$refs.tabbedNav.removeEventListener('scroll', this._checkScroll);
  },

  updated() {
    this._checkOverflow();
  },

  data() {
    return {
      _uiCallbacks: new UiCallbacks(),
      isOverflow: false,
    };
  },

});
