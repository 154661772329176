













import Vue from 'vue';
import ArrowButton from '@/configurator/components/utils/ArrowButton.vue';

export default Vue.extend({
  name: 'OverflowIndicator.vue',

  components: {
    ArrowButton,
  },

  props: {
    isShown: Boolean,
    onClick: Function,
    direction: String,
  },

});
