import {RapiMaterial, RapiItem} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {KernelValue, UiPossibleChild} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {Nullable} from '@/common/utils/types';

export interface GridViewElement {
  key: string;
  payload: RapiMaterial | KernelValue | UiPossibleChild | RapiItem;
}

export interface GridViewGroup {
  id: string;
  label: Nullable<string>;
  elements: GridViewElement[];
}

export const toGridViewElements = (elements: Array<RapiMaterial | KernelValue | UiPossibleChild | RapiItem>, key: string): GridViewElement[] => {
  return elements.map<GridViewElement>((element) => {
    return {
      // @ts-ignore
      key: element[key],
      payload: element,
    };
  });
};
