

















import Vue, {PropOptions} from 'vue';
import {GridViewElement, GridViewGroup} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import ExpandedView from '@/configurator/components/grid-view/-utils/ExpandedView.vue';
import CollapsedView from '@/configurator/components/grid-view/-utils/CollapsedView.vue';

export default Vue.extend({

  components: {
    ExpandedView,
    CollapsedView,
  },

  props: {
    isExpanded: Boolean,
    onExpand: Function,
    onSelect: Function,
    elements: Array as PropOptions<GridViewElement[]>,
    selectedElement: Object as PropOptions<GridViewElement>,
    groups: Array as PropOptions<GridViewGroup[]>,
    onCollapse: Function,
  },

  computed: {
    selectedElementWrapped(): GridViewElement {
      return this.selectedElement || {key: null, value: null};
    },
  },

});
