































































































import Vue, {VueConstructor, PropOptions} from 'vue';
import ExpandedHeader from '@/configurator/components/grid-view/-utils/ExpandedHeader.vue';
// @ts-ignore
import SvgArrow from '@/common/svgs/arrow.svg';
import OptionsParameterValue from '@/configurator/components/parameters/types/OptionsParameterValue.vue';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {GridViewElement, GridViewGroup} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import {hasVerticalOverflow} from '@/common/utils/dom';
import GridViewElementView from '@/configurator/components/grid-view/-utils/GridViewElement.vue';
import {throttle} from '@/common/utils/helper';
import Spinner from '@/configurator/components/utils/Spinner.vue';

const SEARCH_THRESHOLD = 10;

export default (Vue as VueConstructor<Vue & {
  $refs: {
    elementsContainer: HTMLUListElement,
  },
}>).extend({

  components: {
    ExpandedHeader,
    OptionsParameterValue,
    GridViewElementView,
    SvgIcon,
    Spinner,
  },

  props: {
    groups: Array as PropOptions<GridViewGroup[]>,
    selectedElement: Object as PropOptions<GridViewElement>,
    onSelect: Function,
    onCollapse: Function,
  },
  data() {
    return {
      isOverflow: false,
    };
  },
  methods: {
    setGroup(groupId: string) {
      this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_FILTER, groupId);
    },
    _checkOverflow() {
      this.isOverflow = hasVerticalOverflow(this.$refs.elementsContainer);
    },
    resetFiltering() {
      this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_FILTER, null);
    },
  },
  updated() {
    this._checkOverflow();
  },
  computed: {
    icons(): object {
      return {SvgArrow};
    },
    filteredGroups(): GridViewGroup[] {
      const searchString = this.$store.state.uiState.gridViewSearch;
      const gridViewFilter = this.$store.state.uiState.gridViewFilter;
      if (!searchString && !gridViewFilter) {
        return this.groups;
      }

      return this.groups.reduce((acc, group) => {
        if (gridViewFilter && group.id !== gridViewFilter) {
          return acc;
        }
        let elements = group.elements;
        // didn't use ternary operator for readability
        if (searchString) {
          elements = group.elements.filter(({payload}) => payload.label && payload.label.toLowerCase().includes(searchString));
        }
        if (elements.length) {
          // do not alter original group, create a minimum version what is needed in view
          acc.push({
            id: group.id,
            label: group.label,
            elements,
          });
        }
        return acc;
      }, [] as GridViewGroup[]);
    },

    groupsForFilter(): GridViewGroup[] {
      const isFilterSet = !!this.$store.state.uiState.gridViewFilter;
      const isSearchSet = !!this.$store.state.uiState.gridViewSearch;
      if (this.groups.length < 2) {
        return [];
      }
      if (!isFilterSet && !isSearchSet) {
        return this.groups;
      }
      if (isFilterSet && !isSearchSet) {
        return this.groups;
      }
      return this.filteredGroups;
    },

    showGroupFilter(): boolean {
      const groups = this.groupsForFilter;
      const isSearchSet = !!this.$store.state.uiState.gridViewSearch;
      if (!isSearchSet) {
        return groups.length > 0;
      }
      if (this.filteredGroups.length < 2) {
        return false;
      }
      return true;
    },

    needsSpacing(): boolean {
      if (!this.filteredGroups.length) {
        return false;
      }
      return !this.showGroupFilter && !!this.filteredGroups[0].label;
    },

    isAllSelected(): boolean {
      return !!!this.$store.state.uiState.gridViewFilter;
    },

    noBorderNeeded(): boolean {
      return this.filteredGroups.length < 2;
    },

    needsSearch(): boolean {
      let elementCount = 0;
      for (const group of this.groups) {
        elementCount += group.elements.length;
        if (elementCount > SEARCH_THRESHOLD) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this._checkOverflow = throttle(this._checkOverflow).bind(this);
    window.addEventListener('resize', this._checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this._checkOverflow);
  },
  destroyed() {
    this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_SEARCH, null);
    this.$store.commit(UI_STATE_MUTATIONS.SET_GRID_VIEW_FILTER, null);
  },

});
