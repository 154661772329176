

















import Vue, {PropOptions} from 'vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {getFieldCustomConfigByKey} from '@/common/utils/custom-config';

export default Vue.extend({
  components: {
  },

  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
  },

  data() {
    return {
      config: getFieldCustomConfigByKey(this.parameter.key),
    };
  },

  methods: {
    async setValue(value: string) {
      await this.$sdkConnector.setParameter(this.parameter, value);
    },
    formatLabel(label: string): string {
      const split = label.split(' = ');
      return split.length > 1 ? `${split[0]}<br>(${split[1]})` : split[0];
    },
    getImage(valueKey: string): ImageBitmapSource {
      const path = (this.config.image_prefix ? this.config.image_prefix : '') + valueKey.replace(/ /g, '_').toUpperCase();
      let image;
      try {
        image = require('@/common/images/' + path + '.png');
      } catch (e) {
        try {
          image = require('@/common/images/' + path + '.jpg');
        } catch (e) {
          image = require('@/common/images/fallback.png');
        }
      }
      return image;
    },
  },
});
