


































































import Vue, {PropOptions} from 'vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import OptionsParameterValue from '@/configurator/components/parameters/types/OptionsParameterValue.vue';
// @ts-ignore
import SvgArrowLeft from '@/common/svgs/arrow.svg';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
// @ts-ignore
import {interactionDesktopWidth} from '@/configurator/components/utils/-utils/InteractionContainer.module.scss';
// @ts-ignore
import {optionsParameterPaddingMobile, optionsParameterPaddingDesktop} from '@/configurator/components/parameters/types/-utils/OptionsParameter.module.scss';

import {cssVariableToNumber} from '@/common/utils/dom';
import {getCurrentFontSize} from '@/common/utils/font-size-detector';

const BOOLEAN_VALUES = ['1', '0'];

export default Vue.extend({

  components: {
    OptionsParameterValue,
    SvgIcon,
    // @ts-ignore
    VueSelect: () => import('vue-select'),
  },

  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
  },

  mounted() {
    this.$nextTick(() => {
      const el = this.$el;
      if (el && el.clientWidth > 0) {
        // size minus padding (3 on desktop and 2 on mobile)
        this.width = (el.clientWidth / getCurrentFontSize()) - (this.$store.state.uiState.isDesktop ? 1.5 : 1);
      }
    });
  },

  data() {
    return {
      parameterPadding: this.$store.state.uiState.isDesktop ? cssVariableToNumber(optionsParameterPaddingDesktop) : cssVariableToNumber(optionsParameterPaddingMobile),
      // size minus padding (3 on desktop and 2 on mobile)
      width: cssVariableToNumber(interactionDesktopWidth) - (this.$store.state.uiState.isDesktop ? 1.5 : 1),
    };
  },

  computed: {
    icons(): object {
      return {SvgArrowLeft};
    },
    useDropdown(): boolean {
      if (this.$attrs.uiType === 'Toggle' || this.$attrs.forceButtons) {
        return false;
      }
      const rem = this.parameter.validValues
        .map((value) => {
          if (!value.label) {
            return 0;
          }
          // number of characters times 0.5
          let labelWidth = value.label.length * 0.5;
          // minimum width of label is 2 rem
          if (labelWidth < 2) {
            labelWidth = 2;
          }
          // labelWidth + 2 times button padding + 0.5 for grid gap
          return labelWidth + 2 * this.parameterPadding + 0.5;
        })
        .reduce((acc, value) => acc + value, 0);
      return rem > this.width;
    },
    isBoolean(): boolean {
      return this.parameter.uiType === 'Boolean';
    },
  },

  methods: {
    async setValue(value: string) {
      await this.$sdkConnector.setParameter(this.parameter, value);
    },
    getLabel(label: string): string {
      let labelCopy = label;
      if (this.$attrs.trimUnnecessaryInfo) {
        labelCopy = label.split(', ')[1];
      }
      if (this.isBoolean) {
        return (BOOLEAN_VALUES.includes(labelCopy)) ? (this as any).t.t('params.boolean[' + labelCopy + ']') : labelCopy;
      } else {
        return labelCopy;
      }
    },
    formatValues(values: any) {
      return values.map((value: any) => ({
        ...value,
        label: value.label.split(', ')[1],
      }));
    },
  },

});
