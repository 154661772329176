



















import Vue, {PropOptions} from 'vue';
// @ts-ignore
import SvgAR from '@/common/svgs/ar.svg';

import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import {UI_BUTTON} from '@/configurator/embedding/types';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {OVERLAYS} from '@/configurator/components/-utils/overlays';

type ARButtonSize = 'medium' | 'small';

export default Vue.extend({
  components: {
    SvgIcon,
  },
  props: {
    size: {
      type: String,
      default: 'small',
      validator(value: any) {
        const possibleValues: ARButtonSize[] = ['medium', 'small'];
        return possibleValues.indexOf(value) !== -1;
      },
    } as PropOptions<ARButtonSize>,
  },
  computed: {
    icons(): object {
      return {SvgAR};
    },
  },
  methods: {
    async goToAr() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.AR);
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.OPEN_AR, open: true});
    },
  },
});
