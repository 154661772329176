



































import Vue, {PropOptions} from 'vue';
// @ts-ignore
// import styles because getComputedStyle is very expensive
// ts-ignore because there is a bug with css-modules in vue cli and typescript
import {allItemsInPreview, allItemsInPreviewDesktop} from '@/configurator/components/parameters/types/-utils/ParameterValueElement.module.scss';
import {addMetaHeaderTag} from '@/common/utils/helper';
import {GridViewElement} from '@/configurator/components/grid-view/-utils/GridViewHelper';
import GridViewElementView from '@/configurator/components/grid-view/-utils/GridViewElement.vue';
import WordWrap from '@/configurator/components/utils/WordWrap.vue';

export const MAX_ELEMENT_COUNT = 999;

const SHOW_ALL_TRANSLATION_KEY = 'params.show-all';

export default Vue.extend({

  components: {
    GridViewElementView,
    WordWrap,
  },

  data() {
    return {
      initialSelectedKey: '',
    };
  },

  props: {
    elements: Array as PropOptions<GridViewElement[]>,
    selectedElement: Object as PropOptions<GridViewElement>,
    onExpand: Function,
    onSelect: Function,
  },

  mounted() {
    // used for cloudinary auto image sizing
    addMetaHeaderTag('image-meta', [{
      key: 'http-equiv',
      value: 'Accept-CH',
    }, {
      key: 'content',
      value: 'DPR, Width',
    }]);
  },

  computed: {
    allPreviewElements(): GridViewElement[] {
      if (!this.initialSelectedKey) {
        this.initialSelectedKey = this.selectedElement.key;
      }
      const initialSelected = this.allElements.find((element) => element.key === this.initialSelectedKey);
      const elementsToShow = (this.hasMoreElements) ? this.itemsToShow - 1 : this.itemsToShow;
      const allPreviewElements = this.allElements.slice(0, elementsToShow);
      if (initialSelected && !allPreviewElements.includes(initialSelected)) {
        allPreviewElements.splice(0, 0, initialSelected);
        return allPreviewElements.slice(0, elementsToShow);
      }
      return allPreviewElements;
    },

    allElements(): GridViewElement[] {
      return this.elements;
    },
    stillAvailable(): number {
      const shownElements = this.allElements.length - this.itemsToShow;
      if (shownElements > 0) {
        return shownElements + 1;
      }
      return shownElements;
    },

    hasMoreElements(): boolean {
      return this.stillAvailable > 0;
    },

    itemsToShow(): number {
      return (this.$store.state.uiState.isDesktop) ? allItemsInPreviewDesktop : allItemsInPreview;
    },

    showAllText(): string {
      return (this as any).t.t(SHOW_ALL_TRANSLATION_KEY);
    },

    isCorrectText(): boolean {
      return this.showAllText !== SHOW_ALL_TRANSLATION_KEY;
    },
  },

  filters: {
    maxValue(value: number | string): number {
      if (typeof value === 'string') {
        value = parseFloat(value);
      }
      return value > MAX_ELEMENT_COUNT ? MAX_ELEMENT_COUNT : value;
    },
  },

});

