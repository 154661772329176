














































































































































































































































































import Vue from 'vue';
import ConfiguratorContainer from '@/configurator/components/ConfiguratorContainer.vue';
import TopBar from '@/configurator/components/TopBar.vue';
import BottomBar from '@/configurator/components/BottomBar.vue';
import AddonsTrigger from '@/configurator/components/addons/AddonsTrigger.vue';
import InteractionsContainer from '@/configurator/components/utils/InteractionsContainer.vue';
import DeleteButton from '@/configurator/components/utils/DeleteButton.vue';
import {CORE_DATA_GETTERS, CORE_DATA_MUTATIONS} from '@/configurator/store/core-data';
import {UI_STATE_GETTERS, UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import HeightContainer from '@/configurator/components/utils/HeightContainer.vue';
import CollectionView from '@/configurator/components/collection-view/CollectionView.vue';
import {UiCallbacks} from '@/configurator/business-logic/ui-callback';
import {toBoolean} from '@/common/utils/helper';
import {UI_BUTTON} from '@/configurator/embedding/types';

import {getGeneralCustomConfig, getAttributesCustomConfig} from '@/common/utils/custom-config';
import {createRoomleEntry} from '@/common/utils/create-roomle-entry';
import ZoomButtons from '@/configurator/components/ZoomButtons.vue';
import {API_BASE} from '@/common/utils/api-url';
import {OVERLAYS} from './-utils/overlays';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import SvgMessage from '@/common/svgs/custom/dist/common/message.svg';
import SvgHeart from '@/common/svgs/custom/dist/common/heart.svg';
import SvgConfigurator from '@/common/svgs/custom/dist/common/configurator.svg';
import SvgMinus from '@/common/svgs/custom/dist/common/minus.svg';
import SvgPlus from '@/common/svgs/custom/dist/common/plus.svg';
import SvgId from '@/common/svgs/custom/dist/common/id.svg';
import SvgInfo from '@/common/svgs/custom/dist/common/info.svg';
import SvgClose from '@/common/svgs/close.svg';
import ProductFooter from '@/configurator/components/collection-view/-utils/ProductFooter.vue';

export default Vue.extend({

  data() {
    return {
      _uiCallbacks: new UiCallbacks(),
      modalHintMoveStatus: false,
    };
  },

  created() {
    this.$data._uiCallbacks.onSelectionChange = this.resetScroll.bind(this);
    this.$data._uiCallbacks.onSelectionCancel = this.resetScroll.bind(this);
    this.$sdkConnector.addUiCallback(this.$data._uiCallbacks);

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    };
    // @ts-ignore
    fetch(`${API_BASE}/api/v1/roomle/is-logged-in`, requestOptions)
      .then((res) => res.json())
      .then((json) => this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_LOGGED_IN_GROUP, json.result));
  },

  watch: {
  //   '$store.state.coreData.notFound'() {
  //     if (this.$store.state.coreData.notFound && this.getLook() === 'default') {
  //       this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.OLD_CONFIG, open: true, payload: {
  //         notFoundArticles: this.$store.state.coreData.notFound.notFoundArticles,
  //         notFoundMaterials: this.$store.state.coreData.notFound.notFoundMaterials,
  //       }});
  //     }
  //   },
    '$store.state.uiState.isLoadingInProgress'() {
      if (!this.$store.state.uiState.isLoadingInProgress) {
        if (this.getLook() === 'default') {
          setTimeout(() => {
            this.$data.modalHintMoveStatus = true;
          }, 4000);
        }
      }
    },
    '$store.state.coreData.isEditing'() {
      if (this.$store.state.coreData.isEditing) {
        if (this.getLook() === 'integrated') {
          setTimeout(() => {
            this.$data.modalHintMoveStatus = true;
          }, 3000);
        }
      } else {
        this.$data.modalHintMoveStatus = false;
      }
    },
  },

  destroyed() {
    if (this.$data._uiCallbacks) {
      this.$sdkConnector.removeUiCallback(this.$data._uiCallbacks);
    }
  },

  components: {
    ConfiguratorContainer,
    ParameterGroups: () => import('@/configurator/components/parameters/ParameterGroups.vue'),
    InteractionsContainer,
    TopBar,
    StartConfiguringButton: () => import('@/configurator/components/utils/StartConfiguringButton.vue'),
    BottomBar,
    AddonsTrigger,
    FullscreenContainer: () => import('@/configurator/components/utils/FullscreenContainer.vue'),
    HeightContainer,
    CollectionView,
    DeleteButton,
    BackToWebsite: () => import('@/configurator/components/utils/BackToWebsite.vue'),
    ZoomButtons,
    SvgIcon,
    ProductFooter,
  },

  methods: {
    resetScroll() {
      const scrollArea = this.$el.querySelector('.main-container .height-content');
      scrollArea!.scrollTop = 0;
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
    getHidePrice(): boolean {
      return getGeneralCustomConfig().hidePrice;
    },
    openPreview(): void {
      if (this.getLook() === 'integrated' && !this.$store.state.uiState.isDesktop && !this.$store.state.coreData.isEditing) {
        this.togglePreview();
      }
    },
    togglePreview(): void {
      window.parent.postMessage(this.$store.state.coreData.isEditing ? 'enable overflow' : (this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow'), API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, !this.$store.state.coreData.isEditing);

      // if (this.$store.state.coreData.notFound && this.getLook() === 'integrated') {
      //   setTimeout(() => {
      //     this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.OLD_CONFIG, open: true, payload: {
      //       notFoundArticles: this.$store.state.coreData.notFound.notFoundArticles,
      //       notFoundMaterials: this.$store.state.coreData.notFound.notFoundMaterials,
      //     }});
      //   }, 500);
      // }
    },
    getConfigAttributes(): any {
      const configAttributes = getAttributesCustomConfig();
      return configAttributes;
    },
    async saveDraft() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.SAVEDRAFT);
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.SAVE_DRAFT, open: true});
    },
    async addToCart() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.ADDTOCART);
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.ADD_TO_CART, open: true});
    },
    async requestPrice() {
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage('open request price popup', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);

      const data = await createRoomleEntry(this.$sdkConnector, this.$store, 'request price', (this as any).t.t('part-list.selected-config'));

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'add entry request price popup',
            entryId: data.entryId,
            entryImage: data.entryImage,
            currency: this.$store.state.coreData.currencySymbol,
            price: this.$store.state.coreData.price,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage('error adding entry', API_BASE);
      }
    },
    changeCount(change: string): void {
      if (change === 'down') {
        if (this.$store.state.coreData.count > 1) {
          this.$store.state.coreData.count = this.$store.state.coreData.count - 1;
        }
      } else if (change === 'up') {
        this.$store.state.coreData.count = this.$store.state.coreData.count + 1;
      }
    },
    openIdModal() {
      window.parent.postMessage('open id modal', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);
    },
  },

  computed: {
    showAddonTrigger(): boolean {
      const hasNonGroupAddons = this.$store.getters[CORE_DATA_GETTERS.HAS_NON_GROUP_ADDONS];
      if (!hasNonGroupAddons) {
        return false;
      }
      const {isDesktop, onlyShowAddons, interactionsExpanded, isFullscreen} = this.$store.state.uiState;
      if (isFullscreen || (isDesktop && onlyShowAddons)) {
        return false;
      }
      const hideBecauseOfInteractionsExpandedState = (isDesktop) ? !interactionsExpanded : interactionsExpanded;
      if (hideBecauseOfInteractionsExpandedState) {
        return false;
      }
      if (!this.useRegularLayout) {
        return false;
      }
      if (!toBoolean(this.$store.state.uiState.initData?.buttons?.[UI_BUTTON.ADDONS])) {
        return false;
      }

      return true;
    },
    useRegularLayout(): boolean {
      return this.$store.getters[UI_STATE_GETTERS.USE_REGULAR_LAYOUT];
    },
    expandedGroups(): boolean {
      const isExpanded = this.$store.state.uiState.interactionsExpanded && !this.$store.state.uiState.isFullscreen;
      return this.$store.state.uiState.isDesktop ? !isExpanded : isExpanded;
    },
    isOnOverview(): boolean {
      return this.$store.state.uiState.collectionViewShowsDetails;
    },
    showDelete(): boolean {
      const haveASelectionWithoutRoot = (this.$store.state.uiState.currentSelection && !this.$store.state.uiState.currentSelection.isRoot);
      return this.$store.getters[UI_STATE_GETTERS.USE_REGULAR_LAYOUT] && haveASelectionWithoutRoot;
    },
    showBackToWebsite(): boolean {
      return this.$store.getters[UI_STATE_GETTERS.SHOW_BACK_TO_WEBSITE] && (!!this.$store.state.uiState.isDesktop || !this.useRegularLayout);
    },
    showStartConfiguring(): boolean {
      const showButton = toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.STARTCONFIGURE]);
      if (!showButton) {
        return false;
      }
      const {isLoadingInProgress, isViewOnly} = this.$store.state.uiState;
      return !isLoadingInProgress && isViewOnly;
    },
    title(): string {
      return getGeneralCustomConfig().headline;
    },
    price(): string {
      let {price} = this.$store.state.coreData;

      price = new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2}).format(price);

      const priceWithCurrency = `${this.$store.state.coreData.currencySymbol} ${price}`;
      return priceWithCurrency;
    },
    priceMetaText(): string {
      const taxRate = this.$store.state.coreData.taxRate;
      let priceMetaText = (this as any).t.t('params.price-meta-with');
      if (taxRate === 0) {
        priceMetaText = (this as any).t.t('params.price-meta-without');
      }
      return priceMetaText;
    },
    webTeaser(): string {
      const attributes = this.$store.getters[CORE_DATA_GETTERS.ATTRIBUTES];
      return attributes.web_teaser ? attributes.web_teaser : getGeneralCustomConfig().partListDescription ? getGeneralCustomConfig().partListDescription : '';
    },
    webDescription(): string {
      const attributes = this.$store.getters[CORE_DATA_GETTERS.ATTRIBUTES];
      return attributes.web_description ? attributes.web_description : this.webTeaser;
    },
    shipping(): string {
      return getGeneralCustomConfig().shipping || '';
    },
    icons(): object {
      return {
        SvgMessage,
        SvgHeart,
        SvgConfigurator,
        SvgMinus,
        SvgPlus,
        SvgId,
        SvgClose,
        SvgInfo,
      };
    },
  },
});
