




































































import Vue from 'vue';
import SvgHeart from '@/common/svgs/custom/dist/common/heart.svg';
import SvgMessage from '@/common/svgs/custom/dist/common/message.svg';
import SvgId from '@/common/svgs/custom/dist/common/id.svg';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import {toBoolean} from '@/common/utils/helper';
import {UI_STATE_MUTATIONS} from '@/configurator/store/ui-state';
import {OVERLAYS} from '@/configurator/components/-utils/overlays';
import {UI_BUTTON} from '@/configurator/embedding/types';
import {triggerRequestProduct} from '@/configurator/embedding/helper';

import {getGeneralCustomConfig} from '@/common/utils/custom-config';
import ConfiguratorSelection from '@/configurator/components/ConfiguratorSelection.vue';
import {API_BASE} from '@/common/utils/api-url';
import {createRoomleEntry} from '@/common/utils/create-roomle-entry';
import {CORE_DATA_MUTATIONS} from '../store/core-data';

export default Vue.extend({
  components: {
    SvgIcon,
    ConfiguratorSelection,
  },

  computed: {
    icons(): object {
      return {
        SvgHeart,
        SvgMessage,
        SvgId,
      };
    },
    subLabel(): string {
      const customConfig = getGeneralCustomConfig();
      if (customConfig.subline) {
        return customConfig.subline;
      }

      const {price} = this.$store.state.coreData;
      return (price) ? this.$store.state.coreState.label : this.$store.state.coreState.catalogLabel;
    },
    mainLabel(): string {
      const customConfig = getGeneralCustomConfig();
      if (customConfig.headline) {
        return customConfig.headline;
      }

      return this.$store.state.coreState.label;
    },
    showRequestProductButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.REQUESTPRODUCT], this.$store.state.uiState.isInIframe);
    },
    showRequestPriceButton(): boolean {
      return true;
    },
    showSaveDraftButton(): boolean {
      return toBoolean(this.$store.state.uiState.initData.buttons?.[UI_BUTTON.SAVEDRAFT]);
    },
  },

  methods: {
    async saveDraft() {
      this.$embeddingCallbacks.onButtonClicked(UI_BUTTON.SAVEDRAFT);
      this.$store.commit(UI_STATE_MUTATIONS.SET_OVERLAY_STATE, {overlay: OVERLAYS.SAVE_DRAFT, open: true});
    },
    async requestPrice() {
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage('open request price popup', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);

      const data = await createRoomleEntry(this.$sdkConnector, this.$store, 'request price', (this as any).t.t('part-list.selected-config'));

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'add entry request price popup',
            entryId: data.entryId,
            entryImage: data.entryImage,
            currency: this.$store.state.coreData.currencySymbol,
            price: this.$store.state.coreData.price,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage('error adding entry', API_BASE);
      }
    },
    async requestProduct() {
      await triggerRequestProduct(this.$embeddingCallbacks, this.$sdkConnector, this.$store, this.$analytics);
    },
    openCollection() {
      // @ts-ignore
      this.$refs.configurationSelection.openCollection();
    },
    openIdModal() {
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage('open id modal', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);
    },
    async openEmailModal() {
      window.parent.postMessage(this.getLook() === 'default' ? 'just disable overflow' : 'disable overflow', API_BASE);
      window.parent.postMessage('open sales email modal', API_BASE);
      this.$store.commit(CORE_DATA_MUTATIONS.UPDATE_IS_EDITING, false);

      const data = await createRoomleEntry(this.$sdkConnector, this.$store, 'sales email offer', (this as any).t.t('part-list.selected-config'));

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'add entry sales email modal',
            entryId: data.entryId,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage('error adding entry', API_BASE);
      }
    },
    getLook(): string {
      return getGeneralCustomConfig().look;
    },
  },
});
