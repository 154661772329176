








import Vue from 'vue';

export default Vue.extend({
  name: 'ArrowButton.vue',

  props: {
    onClick: Function,
    direction: String,
  },

});
