

















import Vue from 'vue';
import {Nullable} from '@/common/utils/types';
import {iconByKey} from '@/common/utils/icons';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
import {getGroupCustomConfigByKey} from '@/common/utils/custom-config';

export default Vue.extend({
  components: {
    SvgIcon,
  },

  props: {
    value: String,
    label: String,
    onSelect: Function,
    isBoolean: Boolean,
    selected: Boolean,
    isGroup: Boolean,
  },

  computed: {
    valideOption(): boolean {
      return !!this.text;
    },
    text(): Nullable<string> {
      if (this.label) {
        return this.label;
      }
      if (this.value !== null && this.value !== undefined) {
        return this.value + '';
      }
      return null;
    },
    icon(): Nullable<string> {
      const customConfig = this.config(this.value);
      return iconByKey(customConfig.icon);
    },
  },

  methods: {
    config(configKey: Nullable<string> = null): any {
      const customConfig = getGroupCustomConfigByKey(this.value);
      return (configKey && customConfig[configKey]) ? customConfig[configKey] : customConfig;
    },
  },

});
