





































import Vue, {PropOptions} from 'vue';
import {getImageURL} from '@/common/utils/helper';
import {KernelValue, UiPossibleChild} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import WordWrap from '@/configurator/components/utils/WordWrap.vue';
import {RapiMaterialEmbeddedTextures} from '@/common/utils/parameters';
import {CssHash} from '@/common/utils/types';
import {RapiItem, RapiMaterial} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import SvgIcon from '@/configurator/components/utils/SvgIcon.vue';
// @ts-ignore
import SvgCheck from '@/common/svgs/check.svg';
import ImageWithFallback from '@/configurator/components/utils/ImageWithFallback.vue';

interface RapiItemWitSketch extends RapiItem {
  sketch: string;
}

export default Vue.extend({
  components: {
    WordWrap,
    SvgIcon,
    ImageWithFallback,
  },

  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    payload: Object as PropOptions<UiPossibleChild | KernelValue | RapiMaterial>,
    onSelect: Function,
    selected: Boolean,
  },

  computed: {
    icons(): object {
      return {
        SvgCheck,
      };
    },
    useImage() {
      return !!this.$props.payload.shading;
    },
    isAddon() {
      const {isItem, isComponent} = this.$props.payload as UiPossibleChild;
      return isItem || isComponent;
    },
    asList() {
      return this.$store.state.uiState.showAllAsList && this.$props.expanded;
    },
  },

  methods: {
    getThumbnail(optionValue: KernelValue | UiPossibleChild | RapiItemWitSketch): string {
      if ((optionValue as RapiItem).sketch) {
        return getImageURL((optionValue as RapiItemWitSketch).sketch, 64, 32);
      } else if ((optionValue as UiPossibleChild).perspectiveImage) {
        return getImageURL((optionValue as UiPossibleChild).perspectiveImage, 64, 32);
      } else if ((optionValue as KernelValue).thumbnail) {
        return getImageURL((optionValue as KernelValue).thumbnail, 64, 32);
      }
      return '';
    },

    getMaterialThumbnail(material: RapiMaterialEmbeddedTextures): CssHash {
      const css: CssHash = {};
      let rgb = null;
      if (material.color) {
        rgb = material.color;
      }
      if (material.shading && material.shading.basecolor) {
        rgb = [
          Math.round(material.shading.basecolor.r * 255),
          Math.round(material.shading.basecolor.g * 255),
          Math.round(material.shading.basecolor.b * 255),
        ].join(',');
      }
      if (rgb) {
        css['background-color'] = 'rgb(' + rgb + ')';
      }

      let backgroundImage = null;
      if (material.thumbnail) {
        backgroundImage = getImageURL(material.thumbnail, 64, 32);
      }

      if (!rgb && !material.thumbnail && material.embeddedTextures && material.embeddedTextures.length > 0) {
        console.warn('Material has no color, no thumbnail, fallback to texture!');
      }

      if (backgroundImage) {
        css['background-image'] = 'url(' + backgroundImage + ')';
      }
      return css;

    },

  },

});
