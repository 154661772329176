







































































import Vue, {PropOptions} from 'vue';
import {UiKernelParameter} from '@roomle/web-sdk/lib/definitions/typings/kernel';
import {round, throttle} from '@/common/utils/helper';
// @ts-ignore
import {rangeThumbSize, rangeThumbSizeDesktop} from '@/configurator/components/parameters/types/-utils/RangeParameter.module.scss';
import RoomleConfigurator from '@roomle/web-sdk/lib/definitions/configurator-core/src/roomle-configurator';

export default Vue.extend({
  props: {
    parameter: Object as PropOptions<UiKernelParameter>,
  },

  data() {
    return {
      hasRangeError: false,
      hasSyntaxError: false,
    };
  },

  mounted() {
    this._positionFilled = throttle(this._positionFilled).bind(this);
    window.addEventListener('resize', this._positionFilled);

    this.$el.querySelectorAll<HTMLInputElement>('input[type="text"]').forEach((element) => {
      element.oncontextmenu = (e) => e.preventDefault();
    });
  },

  destroyed() {
    window.removeEventListener('resize', this._positionFilled);
  },

  methods: {

    _resetErrors(): void {
      this.$data.hasRangeError = false;
      this.$data.hasSyntaxError = false;
    },

    _positionFilled(): void {
      const input = this.$el.querySelector<HTMLInputElement>('input[type="range"]');
      const sliderFilled = (input!.parentElement as HTMLElement).querySelector<HTMLElement>('.slider-filled');
      const value = parseFloat(input!.value);
      let percent = round((value - this.settings.min) / (this.settings.max - this.settings.min), 3);
      if (this.settings.min === this.settings.max) {
        percent = 0;
      }
      const thumbSize = (this.$store.state.uiState.isDesktop) ? rangeThumbSizeDesktop : rangeThumbSize;
      const rangeThumbSizeClean = parseFloat(thumbSize.replace('rem').trim());
      sliderFilled!.style.width = 'calc(' + percent * 100 + '% - ' + rangeThumbSizeClean * percent + 'rem - 1px)';
    },

    async onInput(event?: Event): Promise<void> {
      this._positionFilled();
      const inputRange = this.$el.querySelector<HTMLInputElement>('input[type="range"]');
      this._resetErrors();
      if (event) {
        const configurator = (await this.$sdkConnector.api as RoomleConfigurator);
        const formattedValue = configurator.formatValueToUnitString(parseFloat(inputRange!.value), this.$props.parameter).toString();
        this.setValueString(formattedValue);
      }
    },
    async onChange(event: Event): Promise<void> {
      const value = (event.target! as HTMLInputElement).value;
      await this.$sdkConnector.setParameter(this.parameter, value, true);
      this._resetErrors();
    },
    getValueString(): string {
      const inputs = this.$el.querySelectorAll<HTMLInputElement>('input[type="text"]');
      const labels = this.$el.querySelectorAll<HTMLInputElement>('.input-label');
      let valueString = inputs[0].value;
      if (labels[0]) {
        valueString += labels[0].textContent;
      }
      if (inputs.length > 1) {
        valueString += ' ' + inputs[1].value;
        if (labels[1]) {
          valueString += labels[1].textContent;
        }
      }
      return valueString;
    },
    setValueString(valueString: string): void {
      const splitValue = this.splitValue(valueString);
      const inputs = this.$el.querySelectorAll<HTMLInputElement>('input[type="text"]');
      const labels = this.$el.querySelectorAll<HTMLInputElement>('.input-label');
      inputs[0].value = splitValue[0];
      if (labels[0]) {
        labels[0].textContent = splitValue[1];
      }
      if (splitValue.length > 2 && inputs.length > 1) {
        inputs[1].value = splitValue[2];
        if (labels[1]) {
          labels[1].textContent = splitValue[3];
        }
      }
    },
    onFocus(event: FocusEvent): void {
      const inputElement = event.target as HTMLInputElement;
      inputElement?.setSelectionRange(0, inputElement.value.length);
    },
    async onTextInputChange(event: Event): Promise<void> {
      const value = this.getValueString(); // WARNING: save value here so it's still correct after await this.$sdkConnector.api
      this._resetErrors();
      try {
        await this.$sdkConnector.setParameter(this.parameter, value);
        (event.target as HTMLInputElement).blur();
      } catch (error) {
        if (error instanceof RangeError) {
          this.$data.hasRangeError = true;
        } else if (error instanceof SyntaxError) {
          this.$data.hasSyntaxError = true;
        }
        (event.target as HTMLInputElement).select();
      }
    },
    splitValue(value?: string): string[] {
      if (!value) {
        value = this.$props.parameter.valueLabel;
      }
      if (this.isInch) {
        const values = value?.match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g) || [];
        const symbol = value?.includes('\'') ? '\'' : '"';
        let parts = [values[0], symbol];
        if (values?.length > 1) {
          parts = [...parts, ...[values[1], '\"']];
        }
        return parts;
      } else {
        return value?.split(' ') || [];
      }
    },
  },
  computed: {
    settings(): {min: number, max: number, step: number | string} {
      const integerType = 'Integer';
      const setting = {min: 0, max: Number.MAX_VALUE, step: this.$props.parameter.type === integerType ? 1 : 'any'};
      if (this.$props.parameter.validRange) {
        const {valueFrom, valueTo, step, type} = this.$props.parameter.validRange;
        if (valueFrom) {
          setting.min = valueFrom;
        }
        if (valueTo) {
          setting.max = valueTo;
        }
        if (step) {
          setting.step = step;
        } else if (type === integerType) {
          setting.step = 1;
        }
      }
      return setting;
    },
    labels(): {min: string, max: string} {
      const labels = {
        min: this.settings.min.toString(),
        max: this.settings.max.toString(),
      };

      if (this.$props.parameter.validRangeLabels) {
        const {valueFrom, valueTo} = this.$props.parameter.validRangeLabels;
        if (valueFrom) {
          labels.min = valueFrom;
        }
        if (valueTo) {
          labels.max = valueTo;
        }
      }
      return labels;
    },
    isInch(): boolean {
      return this.$props.parameter.valueLabel.includes('\'')
        || this.$props.parameter.valueLabel.includes('\"');
    },
    input1(): {value: string, label: string} {
      const valueParts = this.splitValue();
      return {value: valueParts[0], label: valueParts[1]};
    },
    input2(): {value: string, label: string} {
      const valueParts = this.splitValue();
      return {value: valueParts[2], label: valueParts[3]};
    },
  },

  watch: {
    parameter: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.onInput());
      },
    },
  },

});
