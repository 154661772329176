import { render, staticRenderFns } from "./OverflowIndicator.vue?vue&type=template&id=4ec9d2a2&scoped=true&"
import script from "./OverflowIndicator.vue?vue&type=script&lang=ts&"
export * from "./OverflowIndicator.vue?vue&type=script&lang=ts&"
import style0 from "./OverflowIndicator.vue?vue&type=style&index=0&id=4ec9d2a2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ec9d2a2",
  null
  
)

export default component.exports